<template>
  <div >
    <!-- <Navbar />
    <Sidebar /> -->
    <section class="main no-flex">
      <HistoryTable />
    </section>
  </div>
</template>

<script>
import HistoryTable from '../components/HistoryTable.vue';

export default {
  name: 'Home',
  components: {
    HistoryTable,
  },
}
</script>
<style scoped lang="scss">
@media screen and (max-width: 1190px) {
  .main {
    padding: 90px 0.75rem 15px 0.75rem;
  }
}
</style>
